import { createHashHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createReduxHistoryContext } from 'redux-first-history'

import createRootReducer from '../reducers'

const initialState = {}

const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({
  history: createHashHistory()
  // other options if needed
})

export const createStoreWithMiddleware = applyMiddleware(
  routerMiddleware,
  thunk
)(createStore)

const store = createStoreWithMiddleware(createRootReducer(), initialState)

export const history = createReduxHistory(store)

export default store
